<script>
  import { backgroundColor, menuIsOpen } from "../common/Service";
  import Headr from "./Header.svelte";
  import Sidebar from "./Sidebar.svelte";
  import Footer from "./Footer.svelte";

  backgroundColor.set("");
  menuIsOpen.set(false);
</script>

<div class="flex flex-col h-screen relative">
  <Headr />
  <main class="flex-1 overflow-auto ">
    <div class="bg-black-1F">
      <div class="md:max-w-1440 mx-auto  growth-content">
        <div class="font-title tracking-1.2 uppercase pt-p54">
          <h1>Growth plan</h1>
        </div>
      </div>
    </div>

    <div class="bg-black-1F">
      <div class="md:max-w-1440 mx-auto  growth-content">
        <div class=" py-96   slide-1">
          <div class="">
            <div class="text-purple-C6 uppercase text-30 pb-p40 font-title">
              stage 1
            </div>
            <div class="md:w-2/3">
              <div class="mb-4 ">
                <img
                  src="/images/logo-autograph.svg"
                  alt="autograph"
                  class="w-300" />
              </div>
              <div class="text-16 mb-16 ">
                Collect digital autographs on NFTs. Demonstrating the art of the
                possible.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-black-22">
      <div class="md:max-w-1440 mx-auto  growth-content">
        <div class=" py-96   slide-2 content-bg-left">
          <div class="md:pl-20%">
            <div class="text-purple-C6 uppercase text-30 pb-p40 font-title">
              stage 2
            </div>
            <div class="text-30 mb-4 font-title">NFT Transmutation</div>
            <div class="text-16 mb-16 ">
              Recreate, remix, combine, mutate & transform NFTs. Kickstarting
              the age of synthetic NFTs.
            </div>
            <div class="text-30  font-title mb-4">Advanced NFT Trading</div>
            <div class="text-16 mb-16 ">
              Permissionless attestation-based trading mechanisms: reverse
              offering, multiparty consent, creating specific transactional
              contracts and more.
            </div>
            <div class="text-30  font-title mb-4">Identity For Metaverse</div>
            <div class="text-16">
              Decentralised identity attestation that allows cryptographic
              linkage of Web2 ID to Web3.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-black-1F">
      <div class="md:max-w-1440 mx-auto  growth-content">
        <div class=" py-96   slide-3 ">
          <div class="">
            <div class="text-purple-C6 uppercase text-30 pb-p40 font-title">
              stage 3
            </div>
            <div class="md:w-2/3">
              <div class="text-30 mb-4 font-title">
                The Base Contract Of Collectibles
              </div>
              <div class="text-16 mb-16 ">
                AlchemyNFT becomes the infrastructure for all collectible NFTs:
                Gas-less minting, Advanced trading, Unlimited transmutation,
                Web2 ID authentication.
              </div>
              <div class="text-30  font-title mb-4">
                TokenScript For Collectibles
              </div>
              <div class="text-16 mb-16 ">
                Creators use TokenScript to bring an unlimited number of
                utilities to their NFTs. Allows adding redeemable rights and
                advanced rendering of tokens across all platforms.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-black-22">
      <div class="md:max-w-1440 mx-auto  growth-content">
        <div class=" py-96   slide-4 content-bg-left">
          <div class="md:pl-20%">
            <div class="text-purple-C6 uppercase text-30 pb-p40 font-title">
              stage 4
            </div>
            <div class="">
              <div class="text-30 mb-4 font-title">
                Infrastructure For All NFTs
              </div>
              <div class="text-16 mb-16 ">
                All NFTs including collectables, redeemables and consumables use
                AlchemyNFT components - contracts and TokenScript - as
                foundation.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-black-1F">
      <div class="md:max-w-1440 mx-auto  growth-content">
        <div class=" py-96   slide-5 ">
          <div class="">
            <div class="text-purple-C6 uppercase text-30 pb-p40 font-title">
              stage 5
            </div>
            <div class="md:w-2/3">
              <div class="text-30 mb-4 font-title">
                TokenScript For All NFTs
              </div>
              <div class="text-16 mb-16 ">
                TokenScript enables Token Issuers to add context, security, and
                cross-platform functionality to their NFTs with a single file.
              </div>
              <div class="text-30  font-title mb-4">
                TokenScript For All Tokens
              </div>
              <div class="text-16 mb-16 ">
                TokenScript completes tokenization. Fulfills Web 3.0 vision:
                technology-enabled free market and an integrated web.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Sidebar />
    <Footer />
  </main>
</div>
