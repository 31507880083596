<script lang="ts">
  import { menuIsOpen } from "../common/Service";
  import Footer from "../components/Footer.svelte";

  let menus = [
    {
      title: "homepage",
      url: "",
    },
    {
      title: "Growth plan",
      url: "growthplan",
    },
    {
      title: "team",
      url: "team",
    },
    {
      title: "contact us",
      url: "mailto:info@alchemynft.org",
    },
  ];

  function menuClick(url: string) {
    menuIsOpen.set(false);
  }
</script>

<aside
  class="absolute w-full bg-black-15 pt-10 shadow-lg md:hidden {$menuIsOpen
    ? 'open'
    : 'hidden'}"
>
  <div class="mb-10 px-6">
    {#each menus as member, i}
      <a
        class="h-p74 font-text uppercase text-24 border-b border-black-mb my-auto leading-p74 cursor-pointer hover-animation block"
        on:click={() => menuClick(member.url)}
        href={member.url.indexOf("mailto") > -1 ? member.url : "/" + member.url}
      >
        {member.title}
      </a>
    {/each}
  </div>
  <Footer postion="absolute bottom-0 w-full " />
</aside>

<style>
  aside {
    left: -100%;
    transition: left 0.3s ease-in-out;
    height: calc(100vh - 72px);
  }

  .open {
    left: 0;
    top: 71px;
  }
</style>
