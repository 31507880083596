<script lang="ts">
  import { footerIcons } from "../common/Const";
  export let postion = "";
</script>

<div class="bg-black-22 py-16  nft-content sm:py-10 {postion} ">
  <div class="md:max-w-1440   mx-auto">
    <div class="md:w-360 mx-auto text-center ">
      <div class="grid grid-cols-4   justify-items-center gap-6">
        {#each footerIcons as item}
          <a
            href={item.url}
            class="cursor-pointer social-icon-{item.id} w-p43 h-p40 "
            target="_blank">&nbsp;</a
          >
        {/each}
      </div>
      <div class="font-text text-16 mt-8">
        Made with ❤️ by <a
          class="cursor-pointer underline"
          target="_blank"
          href="https://alphawallet.com/">AlphaWallet</a
        >
      </div>
    </div>
  </div>
</div>
