<script>
  import { teamIcons } from "../common/Const";
  import { backgroundColor, menuIsOpen } from "../common/Service";
  import Headr from "./Header.svelte";
  import Sidebar from "./Sidebar.svelte";
  import Footer from "./Footer.svelte";

  backgroundColor.set("");
  menuIsOpen.set(false);

  let teams = [
    {
      src: "/images/team/profile-victor.png",
      name: "Victor Zhang",
      jobtitle: "Project Lead",
      company: "Co-Founder of AlphaWallet.",
      desc:
        "4 years experience in blockchain, over 10 years business experience with 7 years experience managing multinational teams and businesses across Asia countries. Core team of a tech startup that was sold to eBay for US$165m in 2016.",
      twitter: "https://twitter.com/Victor928",
      medium: "https://medium.com/@victor.zhang",
      linkedin: "https://www.linkedin.com/in/zhangzhongnan/",
    },
    {
      src: "/images/team/profile-weiwu.png",
      name: "Weiwu Zhang",
      jobtitle: "Tech Lead",
      company: "Co-Founder of AlphaWallet.",
      desc:
        "9+ years experience in blockchain, from alt currency design to trading algorithms. Served as Blockchain Architect in Commonwealth Bank of Australia (largest financial institution in Australia). R3 global architect office. Architect for 3 altcoins and 7 ICO projects.",
      twitter: "https://twitter.com/zhangweiwu",
      medium: "https://medium.com/@zhangweiwu",
      linkedin: "https://www.linkedin.com/in/weiwuzhang/",
    },
    {
      src: "/images/team/profile-james.png",
      name: "James Brown",
      jobtitle: "Lead Developer",
      company: "Co-Founder of AlphaWallet.",
      desc:
        "6 years experience in blockchain, served as Lead dev for blockchain projects in Commonwealth Bank of Australia. Finalized several internal bank blockchain projects, including a new production blockchain identity system. Made compiler for PS3. 20+ years software experience.",
      twitter: "https://twitter.com/TallyDigital",
      medium: "https://medium.com/@james.brown_76621",
      linkedin: "https://www.linkedin.com/in/james-brown-61973b18/",
    },
    {
      src: "/images/team/profile-lucas.png",
      name: "Lucas Toledo",
      jobtitle: "Design & research",
      company: "",
      desc:
        "8+ years experience working cross-functional roles between design, UX research and product strategy. Product Lead with cybersecurity software SpiderLabs Japan. Ran 2 agencies in adtech and eCommerce spaces.",
      twitter: "https://twitter.com/cheapsimulation",
      medium: "",
      linkedin: "https://www.linkedin.com/in/toledotoledo/",
    },
    {
      src: "/images/team/profile-sunil.png",
      name: "Sunil Tom Jose",
      jobtitle: "Project Manager",
      company: "",
      desc:
        "8+ years of experience in helping organisations adopt emerging technologies such as blockchain, big data and AI. Key blockchain projects include world’s 1st trade finance experiment using blockchain, 1st commercial blockchain in South Africa. Stanford LEAD certification on Corporate Innovation.",
      twitter: "https://twitter.com/SunilTomJose",
      medium: "https://medium.com/@SunilTomJose",
      linkedin: "https://www.linkedin.com/in/sunil-tom-jose/",
    },
    {
      src: "/images/team/profile-tore.png",
      name: "Tore Kasper Frederiksen",
      jobtitle: "Cryptography Engineer",
      company: "",
      desc:
        "Senior Cryptography Engineer specialised in the theoretical and practical aspects of secure multi-party computation and related bleeding edge crypto topics. Ph.D. from Aarhus University, Denmark, on secure multiparty computation.",

      twitter: "",
      medium: "",
      linkedin: "https://www.linkedin.com/in/jot2re ",
    },
    {
      src: "/images/team/profile-hu.png",
      name: "Hu Jian",
      jobtitle: "Web Developer",
      company: "",
      desc:
        "20+ years experience in R&D and enterprise development. 3 years of experience in blockchain. Specialized with big data projects, architect for a high performance IoT platform and many other enterprise projects. Avid advocate of adoption of good practices and tools.",
      twitter: "https://twitter.com/foxgem",
      medium: "",
      linkedin: "",
    },
    {
      src: "/images/team/profile-doge.png",
      name: "Community  Managers",
      jobtitle: "to be recruited",
      company: "",
      desc:
        "The community managers will kickstart and curate the community for different regions and industries, catering to their unique needs and demands. The community managers will play a crucial role as the ecosystem is integral to the success of the project.",
    },
    { src: "/images/team/hiring.svg", name: "" },
  ];
</script>

<div class="flex flex-col h-screen relative">
  <Headr />
  <main class="flex-1 overflow-auto ">
    <div class="md:mt-14  team-content phone:mt-12">
      <div class="md:max-w-1440   mx-auto">
        <div class="font-title tracking-1.2">
          <h1>Team</h1>
        </div>

        <div
          class="md:grid md:grid-cols-3 team-member lg:mb-40 phone:grid-cols-1 "
        >
          {#each teams as member, i}
            {#if member.name}
              <div class="mt-12 team-items ">
                <div class="w-160 h-200 mb-p25">
                  <img src={member.src} alt={member.name} />
                </div>
                <div class="font-title text-32f tracking-0.66 uppercase">
                  {member.name}
                </div>
                <div
                  class="font-title text-24 tracking-0.66 mb-p9 uppercase text-black-9b"
                >
                  {member.jobtitle}
                </div>
                <div class="font-text text-16 mb-4">
                  {#if member.company}{member.company}<br />{/if}

                  {member.desc}
                </div>
                <div class="grid grid-cols-3 gap-4 w-104">
                  {#each teamIcons as item}
                    {#if member[item.id]}
                      <a href={member[item.id]}
                        ><img alt={item.id} class="h-6" src={item.src} /></a
                      >
                    {/if}
                  {/each}
                </div>
              </div>
            {:else}
              <div class="mt-12 relative team-items  hiring-div bg-purple-67">
                <div class="text-center text-32 font-title lg:py-217">
                  Interested to join? <br />We’re hiring!
                </div>
              </div>
            {/if}
          {/each}
        </div>
      </div>
    </div>
    <Sidebar />
    <Footer />
  </main>
</div>
